import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppointmentData } from "../../config/bookingDataConfig"
import { capitalize, groupBy, lowerCase, upperCase } from "lodash"
// import moment from "moment"
import moment from "moment-timezone"
import { openToastify } from "../../store/actions/toastifyAction"
import {
  REDUX_CUSTOMER_DEFAULTS,
  REDUX_STORE_DEFAULTS,
  REDUX_TOASTIFY_DEFAULTS,
} from "../../store/config"
import { prettyFormat } from "@testing-library/react"
import {
  createBooking,
  getBookingSlots,
  updateBooking,
} from "../../../axios/api/apiModule"
import { bookingAction } from "../../store/actions/bookingAction"
import Loader from "../../../components/asserts/Spin.gif"
import {
  DropDownListComponent,
  MultiSelectComponent,
  DropDownList,
  MultiSelect,
  CheckBoxSelection,
  AutoCompleteComponent,
} from "@syncfusion/ej2-react-dropdowns"
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
  Resize,
  DragAndDrop,
  ResourcesDirective,
  ResourceDirective,
  TimelineViews,
} from "@syncfusion/ej2-react-schedule"
import { DataManager, Query, WebApiAdaptor } from "@syncfusion/ej2-data"
import "./CreateBookingStyle.css"
import GoogleMap from "../../data/modal/google_map/GoogleMap"
import CryptoJS from "crypto-js"
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  doc,
} from "firebase/firestore"
import { db } from "../../../firebase-config"
import { customersAction } from "../../store/actions/customersAction"
import { SendMsgEmail } from "../../data/modal/SendMsgEmail"
import _ from "lodash"

const CreateBooking = (props) => {
  const dispatch = useDispatch()
  const { data, onClick, isEdit } = props
  const _id = data?._id
  const [isData, setIsData] = useState({
    firstName: data?.firstName ?? "",
    lastName: data?.lastName ?? "",
    phoneNumber: data?.phoneNumber ?? "",
    email: data?.email ?? "",
    bookingStatus: data?.bookingStatus ?? "confirmed",
    locationType: data?.locationType ?? "mobile",
    location: data?.location ?? "",
    bookingDate: data?.bookingDate ?? new Date(),
    bookingSlot: data?.bookingSlot ?? 1,
    adminAuthored: data?.adminAuthored ?? true,
    booking: data?.booking ?? [1],
    tax: data?.tax ?? 5,
    isWaterAvailable: data?.isWaterAvailable ?? true,
    isElectricityAvailable: data?.isElectrictyAvailable ?? true,
    electricityBill: data?.electricityBill ?? 0,
    serviceTotal: data?.serviceTotal ?? 0,
    addonServiceTotal: data?.addonServiceTotal ?? 0,
    taxAmount: data?.taxAmount ?? 0,
    subTotal: data?.subTotal ?? 0,
    couponDiscountAmount: data?.couponDiscountAmount ?? 0,
    giftCardDiscountAmount: data?.giftCardDiscountAmount ?? 0,
    totalAmount: data?.totalAmount ?? 0,
  })
  const [allBookingSlot, setAllBookingSlot] = useState([])
  const [isSendStatus, setIsSendStatus] = useState({
    sendMsg: data?.bookingCnfMsg?.msgSend ?? false,
    sendEmail: data?.bookingCnfMsg?.emailSend ?? false,
  })
  let { shopBookingData, mobileBookingData, isBookingUpdated } = useSelector(
    (state) => state.bookingReducer
  )
  let { customerListData } = useSelector((state) => state.customerReducer)
  const [isLoading, setisLoading] = useState(false)
  const [isBookingDate, setIsBookingDate] = useState(
    moment(isData.bookingDate).utc()
  )
  const [isBookingTime, setIsBookingTime] = useState()

  const mobileTimeSlots = [
    "8:00am",
    "8:30am",
    "9:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "3:00pm",
    "3:30pm",
    "4:00pm",
  ]
  const shopTimeSlots = [
    "9:30am",
    "9:45am",
    "10:00am",
    "12:30pm",
    "12:45pm",
    "1:00pm",
    "4:00pm",
    "4:15pm",
    "4:30pm",
  ]

  //Add Users to Firebase (Start)
  const usersCollectionRef = collection(db, "booked-clients")
  const saveDataFireBase = async (newBookingId = "") => {
    const values = {
      firstName: isData?.firstName.toString() ?? "",
      phoneNumber: isData?.phoneNumber.toString() ?? "",
      email: isData?.email.toString() ?? "",
      address: isData?.location.toString() ?? "",
      createdAt: new Date().toISOString() ?? "",
      bookingInfo: [
        { bookingId: newBookingId, createdAt: new Date().toISOString() },
      ], // Initialize bookingId as an array
    }
    // console.log(values, "saveDataFireBase")

    try {
      // Create queries to check if the email or phone number already exists
      // const emailQuery = query(
      //   usersCollectionRef,
      //   where("email", "==", values.email)
      // )
      const phoneQuery = query(
        usersCollectionRef,
        where("phoneNumber", "==", values.phoneNumber)
      )

      // Execute both queries
      // const [emailQuerySnapshot, phoneQuerySnapshot] = await Promise.all([
      //   getDocs(emailQuery),
      //   getDocs(phoneQuery),
      // ])
      const [phoneQuerySnapshot] = await Promise.all([getDocs(phoneQuery)])

      // Combine the results from both queries
      // const combinedSnapshots = [
      //   ...emailQuerySnapshot.docs,
      //   ...phoneQuerySnapshot.docs,
      // ]
      const combinedSnapshots = [...phoneQuerySnapshot.docs]

      if (combinedSnapshots.length === 0) {
        // If no documents are found with the same email or phone number, add a new document
        const res = await addDoc(usersCollectionRef, { ...values })
        // Create the new data array based on the existing state and the new data
        const newAddData = [...customerListData, { ...values, id: res.id }]
        dispatch(
          customersAction(REDUX_CUSTOMER_DEFAULTS.GET_EMPLOYEES(), newAddData)
        )
        console.log("New user added successfully.")
      } else {
        // Iterate over each document found with the same email
        combinedSnapshots.forEach(async (docSnapshot) => {
          const userDocRef = doc(db, "booked-clients", docSnapshot.id)

          // Fetch the current document data to check the address
          const userDocData = docSnapshot.data()

          // Check if the address is empty or undefined
          const currentAddress = userDocData.address ?? ""
          const updatedFields = {}

          // Update the address only if it is currently empty
          if (!currentAddress.trim()) {
            updatedFields.address = values.address
          }

          // Always update bookingInfo by adding new booking entry
          // Check if bookingInfo exists; if not, initialize it
          // Initialize or add to the bookingInfo field
          const newBookingEntry = {
            bookingId: newBookingId,
            createdAt: new Date().toISOString(),
          }

          if (userDocData.bookingInfo) {
            // If bookingInfo exists, use arrayUnion to add a new booking entry
            updatedFields.bookingInfo = arrayUnion(newBookingEntry)
          } else {
            // If bookingInfo does not exist, set it as a new array with the new entry
            updatedFields.bookingInfo = [newBookingEntry]
          }

          // Perform the update with conditional fields
          await updateDoc(userDocRef, updatedFields)
        })
      }
    } catch (error) {
      console.error("Error checking or adding document: ", error)
    }
  }
  //Add Users to Firebase (End)

  // Function to update Redux state with the updated booking data
  const updateBookingInRedux = (data) => {
    if (data.locationType === "mobile") {
      // Update mobile bookings
      const updatedMobileBookings = mobileBookingData.bookings.map((booking) =>
        booking._id === data._id ? { ...booking, ...data } : booking
      )
      console.log(updatedMobileBookings, "updatedMobileBookings", data)

      dispatch(
        bookingAction(REDUX_STORE_DEFAULTS.UPDATE_MOBILE_BOOKING_DATA(), {
          ...mobileBookingData,
          bookings: updatedMobileBookings,
        })
      )
    } else {
      // Update shop bookings
      const updatedShopBookings = shopBookingData.bookings.map((booking) =>
        booking._id === data._id ? { ...booking, ...data } : booking
      )
      console.log(updatedShopBookings, "updatedShopBookings", data)

      dispatch(
        bookingAction(REDUX_STORE_DEFAULTS.UPDATE_SHOP_BOOKING_DATA(), {
          ...shopBookingData,
          bookings: updatedShopBookings,
        })
      )
    }
  }

  // Handle notification (START)
  function removeHTMLTags(str) {
    return str.replace(/<\/?[^>]+(>|$)/g, "")
  }
  // Function to handle changes and send notifications
  const handleNotification = (data) => {
    const originalBooking = [
      ...shopBookingData.bookings,
      ...mobileBookingData.bookings,
    ].find((booking) => booking._id === data._id)

    const updatedFields = {}

    // Compare fields between original and updated booking
    Object.keys(data).forEach((key) => {
      // Skip bookingCnfMsg field
      if (key === "bookingCnfMsg") return
      if (key === "adminAuthored") return

      const originalValue = originalBooking[key]
      const updatedValue = data[key]

      // Check for deep equality if the value is an object or an array
      if (_.isObject(originalValue) || _.isArray(originalValue)) {
        if (!_.isEqual(originalValue, updatedValue)) {
          // Only include the required fields in the comparison
          if (key === "booking") {
            updatedFields[key] = {
              oldValue: formatBookingDetails(originalValue),
              newValue: formatBookingDetails(updatedValue),
            }
          } else {
            updatedFields[key] = {
              oldValue: originalValue,
              newValue: updatedValue,
            }
          }
        }
      } else if (key === "bookingDate") {
        // Special case for dates, if needed
        if (
          new Date(originalValue).getTime() !== new Date(updatedValue).getTime()
        ) {
          updatedFields[key] = {
            oldValue: moment(originalValue).utc().format("YYYY-MM-DD hh:mm A"),
            newValue: moment(updatedValue).utc().format("YYYY-MM-DD hh:mm A"),
          }
        }
      } else {
        // Default comparison for primitive values (string, number, etc.)
        if (originalValue !== updatedValue) {
          updatedFields[key] = {
            oldValue: originalValue,
            newValue: updatedValue,
          }
        }
      }
    })

    // If there are updated fields, send a notification
    if (Object.keys(updatedFields).length > 0) {
      let notificationMessage = Object.keys(updatedFields)
        .map((key) => {
          if (key === "booking") {
            const oldValue = updatedFields[key].oldValue
            const newValue = updatedFields[key].newValue
            return (
              `Updated service: ${oldValue.serviceName} (${oldValue.serviceDesc}) - $${oldValue.price} → ${newValue.serviceName} (${newValue.serviceDesc}) - $${newValue.price},` +
              getAddonChanges(oldValue.addonService, newValue.addonService)
            )
          }
          return `Updated ${key}: ${updatedFields[key].oldValue} → ${updatedFields[key].newValue}`
        })
        .join(",\n </br>")

      // Add details of all bookings to the notification message
      notificationMessage += `
        </br>
        </br>
        <strong>Booking Details:</strong>
        </br>
        <strong>Total Service Amount:</strong> $${data.serviceTotal}
        </br>
        <strong>Total Addon Service Amount:</strong> $${data.addonServiceTotal}
        </br>
        <strong>Subtotal:</strong> $${data.subTotal}
        </br>
        <strong>Tax Amount:</strong> $${data.taxAmount}
        </br>
        <strong>Total Amount:</strong> $${data.totalAmount}
        </br>
        <strong>Coupon Discount Amount:</strong> $${data.couponDiscountAmount}
        </br>
        <strong>Gift Card Discount Amount:</strong> $${
          data.giftCardDiscountAmount
        }
        </br>
        <strong>Booking Time Slot:</strong> ${moment(data.bookingTimeSlot)
          .utc()
          .format("YYYY-MM-DD hh:mm A")}
        </br>
        <strong>Is Water Available:</strong> ${
          data.isWaterAvailable ? "Yes" : "No"
        }
        </br>
        <strong>Is Electricity Available:</strong> ${
          data.isElectrictyAvailable ? "Yes" : "No"
        }
        </br>
        <strong>Electricity Bill:</strong> $${data.electricityBill}
        </br>
        <strong>Booking ID:</strong> ${data.bId}
        </br>
        <strong>Created At:</strong> ${moment(data.createdAt)
          .utc()
          .format("YYYY-MM-DD hh:mm A")}
        </br>
        </br>
        <strong>Services Details:</strong>
        </br>
        `
      // Loop through each service in the booking array and add its details
      data.booking.forEach((bookingItem, index) => {
        notificationMessage += `
          <strong>Service #${index + 1}:</strong>
          </br>
          Vehicle Type: ${bookingItem.service.vehicleType}
          </br>
          Service Name: ${bookingItem.service.serviceName}
          </br>
          Service Description: ${bookingItem.service.serviceDesc}
          </br>
          Service Price: $${bookingItem.servicePrice}
          </br>
          Addon Total Price: $${bookingItem.addonTotalPrice}
          </br>
          Total Price: $${bookingItem.totalPrice}
          </br>
          <strong>Addon Services:</strong>
          </br>
        `
        // Loop through each addon service and add its details
        if (bookingItem.addonService && bookingItem.addonService.length > 0) {
          bookingItem.addonService.forEach((addon, addonIndex) => {
            notificationMessage += `
            Addon #${addonIndex + 1}: ${addon.addonSericeName} - $${
              addon.addonPrice
            }
            </br>
          `
          })
        } else {
          notificationMessage += "No addon services."
        }
        notificationMessage += `
          </br>
          <hr/>
        `
      })
      console.log("Notification Message:", notificationMessage)

      // Send notification (either email/SMS)

      if (data?.bookingCnfMsg?.emailSend) {
        SendMsgEmail(
          "email",
          "Booking Updated",
          notificationMessage,
          data.email,
          dispatch
        )
      }
      setTimeout(() => {
        if (data?.bookingCnfMsg?.msgSend) {
          SendMsgEmail(
            "sms",
            "Booking Updated",
            removeHTMLTags(notificationMessage),
            data.phoneNumber,
            dispatch
          )
        }
      }, 1500)
    }
  }
  // Function to format the booking details and remove unwanted data
  const formatBookingDetails = (booking) => {
    const serviceDetails = booking[0]?.service || {}
    const formattedBooking = {
      serviceName: serviceDetails.serviceName,
      serviceDesc: serviceDetails.serviceDesc,
      price: serviceDetails.price,
      addonService: booking[0]?.addonService || [],
      addonTotalPrice: booking[0]?.addonTotalPrice || 0,
    }
    return formattedBooking
  }
  // Function to generate addon service changes
  const getAddonChanges = (oldAddons, newAddons) => {
    const changes = []
    oldAddons.forEach((oldAddon, index) => {
      const newAddon = newAddons[index]
      if (
        oldAddon?.addonServiceId !== newAddon?.addonServiceId ||
        oldAddon?.addonPrice !== newAddon?.addonPrice
      ) {
        changes.push(
          `Updated Addon Service: ${oldAddon?.addonServiceId ?? ""} ($${
            oldAddon?.addonPrice ?? 0
          }) → ${newAddon?.addonServiceId ?? ""} ($${
            newAddon?.addonPrice ?? 0
          })\n </br>`
        )
      }
    })
    return changes.length > 0 ? changes.join(",\n </br>") : ""
  }
  // Handle notification (End)

  // Update Booking (START)
  const update = () => {
    dispatch(
      bookingAction(
        REDUX_STORE_DEFAULTS.UPDATE_BOOKING_DATA(),
        !isBookingUpdated
      )
    )
    setisLoading(false)
  }
  const updateTimeToDateString = (dateString, time) => {
    // Create a moment object from the input date string
    let date = moment(dateString).tz("UTC")

    // Extract hours and minutes from the time string
    const [timePart, period] = time.split(/(am|pm)/i)
    let [hours, minutes] = timePart.split(":").map(Number)

    // Convert hours to 24-hour format if necessary
    if (period.toLowerCase() === "pm" && hours !== 12) {
      hours += 12
    } else if (period.toLowerCase() === "am" && hours === 12) {
      hours = 0
    }

    // Set the desired time
    date.set({ hour: hours, minute: minutes, second: 0, millisecond: 0 })

    // date.add(1, 'hours');

    // Return the updated date string in ISO format
    return date.toISOString()
  }
  const updateSomeBookingData = (bookingData) => {
    return [bookingData].map((booking) => {
      const timeSlots =
        booking.locationType === "mobile" ? mobileTimeSlots : shopTimeSlots
      const timeSlot = timeSlots[booking.bookingSlot - 1] // -1 to convert slot number to index
      let updatedDate
      if (booking.adminAuthored) {
        const [hours, minutes, seconds] = isBookingTime.split(":")
        updatedDate = moment(booking.bookingDate).utc()
        updatedDate.set({
          hour: hours,
          minute: minutes,
          second: 0,
          millisecond: 0,
        })
      } else {
        updatedDate = updateTimeToDateString(booking.bookingDate, timeSlot)
      }
      return {
        ...booking,
        bookingTimeSlot: moment(updatedDate).utc().toISOString(),
        bId: generateHash(booking._id),
        // bookingDate: updatedDate,
      }
    })
  }
  const handleEditBookingApi = async () => {
    try {
      const response = await updateBooking({ _id, updatedData: isData })
      console.log(response)
      if (response.success) {
        dispatch(
          openToastify(
            "Booking Updated Successfully",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
          )
        )
        const newBookingData = updateSomeBookingData(response.msg)
        handleNotification(response.msg)
        setTimeout(() => {
          updateBookingInRedux(newBookingData[0])
          // update()
          onClick()
        }, 3000) // 1000ms = 1 second delay, adjust the delay as needed
      }
    } catch (error) {
      console.log(error)
      dispatch(
        openToastify(
          "Something Went Wrong",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
        )
      )
      setisLoading(false)
    }
  }
  // Update Booking (END)

  const generateHash = (s) => {
    // Hash the string using SHA-256
    const hash = CryptoJS.SHA256(s).toString(CryptoJS.enc.Hex)

    // Convert the first 8 characters of the hash to an integer
    const largeNumber = parseInt(hash.substring(0, 8), 16)

    // Map the large number to a 4-digit number
    const fourDigitHash = largeNumber % 10000

    return fourDigitHash.toString().padStart(4, "0") // Ensure it's always 4 digits
  }
  const addSomeBookingData = (bookingData) => {
    // Ensure bookingData is an array or wrap it in an array if it's a single object
    const bookings = Array.isArray(bookingData) ? bookingData : [bookingData]

    return bookings.map((booking) => {
      console.log([booking], "bookingData")

      const timeSlots =
        booking.locationType === "mobile" ? mobileTimeSlots : shopTimeSlots
      const timeSlot = timeSlots[booking.bookingSlot - 1] // -1 to convert slot number to index
      let updatedDate

      if (booking.adminAuthored) {
        updatedDate = booking.bookingDate
      } else {
        updatedDate = addTimeToDateString(booking.bookingDate, timeSlot)
      }

      // Return the updated booking object
      return {
        ...booking,
        bookingTimeSlot: updatedDate,
        bId: generateHash(booking._id), // Generate a unique booking ID
      }
    })
  }
  const handleCreateBookingApi = async () => {
    setisLoading(true)
    try {
      const params = {
        ...isSendStatus,
        data: isData,
      }
      console.log(params)
      const response = await createBooking(params)
      console.log(response)
      if (response.success) {
        dispatch(
          openToastify(
            "Booking Created Successfully",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
          )
        )

        const newBookingData = addSomeBookingData(response.msg) // Process new booking data
        console.log(newBookingData, "newBookingData")
        if (isData.locationType === "mobile") {
          // Handle mobile bookings
          const updatedMobileBookings = [
            ...mobileBookingData.bookings,
            ...newBookingData,
          ]
          console.log(updatedMobileBookings, "updatedMobileBookings")

          dispatch(
            bookingAction(REDUX_STORE_DEFAULTS.UPDATE_MOBILE_BOOKING_DATA(), {
              ...mobileBookingData,
              bookings: updatedMobileBookings,
            })
          )
        } else {
          // Handle shop bookings
          const updatedShopBookings = [
            ...shopBookingData.bookings,
            ...newBookingData,
          ]
          console.log(updatedShopBookings, "updatedShopBookings")

          dispatch(
            bookingAction(REDUX_STORE_DEFAULTS.UPDATE_SHOP_BOOKING_DATA(), {
              ...shopBookingData,
              bookings: updatedShopBookings,
            })
          )
        }

        saveDataFireBase(response.msg._id)
        setTimeout(() => {
          // update()
          onClick() // close
        }, 3000) // 1000ms = 1 second delay, adjust the delay as needed
      }
    } catch (error) {
      console.log(error)
      dispatch(
        openToastify(
          "Something Went Wrong",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
        )
      )
      setisLoading(false)
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()

    setisLoading(true)
    if (isEdit) {
      handleEditBookingApi()
    } else {
      handleCreateBookingApi()
    }
  }

  // Function to convert time slot to Date object
  const convertTimeSlotsToDate = (timeSlot, baseDate) => {
    const date = new Date(baseDate)
    const [time, modifier] = timeSlot.split(/(am|pm)/)
    let [hours, minutes] = time.split(":").map(Number)

    if (modifier === "pm" && hours !== 12) {
      hours += 12
    } else if (modifier === "am" && hours === 12) {
      hours = 0
    }

    date.setHours(hours)
    date.setMinutes(minutes)
    date.setSeconds(0)
    date.setMilliseconds(0)

    return date
  }
  const convertSlotData = (slotdata, timeSlots) => {
    const currentDate = new Date()
    let futureDate = undefined

    if (isData.locationRole === "MOBILE") {
      futureDate = new Date(currentDate.setHours(currentDate.getHours() + 3))
    } else {
      futureDate = new Date(currentDate.setHours(currentDate.getHours() + 1))
    }

    return Object.keys(slotdata).map((key, index) => {
      const timeSlotDate = convertTimeSlotsToDate(
        timeSlots[index],
        isData.bookingDate
      )
      const isSlotValue =
        slotdata[key] === 0 && timeSlotDate < futureDate ? 1 : slotdata[key]

      return {
        id: index + 1,
        isSlot: isSlotValue,
        timeSlot: timeSlotDate,
      }
    })
  }
  const hitGetSlotApi = async (date, locationType) => {
    // setisLoading(true)
    const params = {
      bookingDate: date,
      locationType: lowerCase(locationType),
    }
    // console.log(params)
    try {
      const response = await getBookingSlots(params)
      console.log("getBookingSlots response", response, isData.locationType)
      if (isData.locationType === "mobile") {
        setAllBookingSlot(
          convertSlotData(response.msg.slotdata, mobileTimeSlots)
        )
      } else {
        setAllBookingSlot(convertSlotData(response.msg.slotdata, shopTimeSlots))
      }
    } catch (error) {
      console.error(error)
      dispatch(
        openToastify(
          "Something went wrong!",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
        )
      )
    }
    // setisLoading(false)
  }

  // useEffect(() => {
  //   if (isData.bookingDate && isData.locationType) {
  //     hitGetSlotApi(isData.bookingDate, isData.locationType)
  //   }
  // }, [isData.bookingDate, isData.locationType])

  // Time Code (START)
  const formatDate = (date) => {
    // Create a Date object from the input date
    const d = moment(date)
    // console.log("Date string ", date)

    // Convert the date to UTC by adjusting for the timezone offset
    // const utcYear = d.getUTCFullYear()
    // const utcMonth = `0${d.getUTCMonth() + 1}`.slice(-2)
    // const utcDay = `0${d.getUTCDate()}`.slice(-2)
    const utcYear = moment(d).format("YYYY")
    const utcMonth = moment(d).format("MM")
    const utcDay = moment(d).format("DD")

    // console.log("utcDay string ", utcDay, utcMonth, utcYear)

    // Return the formatted date string in YYYY-MM-DD format
    return `${utcYear}-${utcMonth}-${utcDay}`
  }
  const addTimeToDateString = (time) => {
    let date = moment()
    // Extract hours and minutes from the time string
    const [timePart, period] = time.split(/(am|pm)/i)
    let [hours, minutes] = timePart.split(":").map(Number)

    // Convert hours to 24-hour format if necessary
    if (period.toLowerCase() === "pm" && hours !== 12) {
      hours += 12
    } else if (period.toLowerCase() === "am" && hours === 12) {
      hours = 0
    }

    // Set the desired time
    date.set({ hour: hours, minute: minutes, second: 0, millisecond: 0 })

    // date.add(1, 'hours');
    const h = moment(date).format("HH")
    const m = moment(date).format("mm")

    // Return the updated date string in ISO format
    return `${h}:${m}:00`
    // return date.toISOString()
  }

  //create time for different set of booking
  useEffect(() => {
    if (isData.adminAuthored) {
      // Get the current date
      let now
      if (data?.bookingTimeSlot) {
        now = moment(data.bookingTimeSlot).utc()
      } else {
        now = new Date()
      }
      // Set the desired time
      const desiredTime = now
      const h = moment(desiredTime).format("HH")
      const m = moment(desiredTime).format("mm")
      const s = moment(desiredTime).format("ss")
      setIsBookingTime(`${h}:${m}:00`)
      console.log(`desiredTime=> ${h}:${m}:${s}`)
    } else {
      const timeSlots =
        isData.locationType === "mobile" ? mobileTimeSlots : shopTimeSlots
      const timeSlot = timeSlots[isData.bookingSlot - 1] // -1 to convert slot number to index
      console.log(addTimeToDateString(timeSlot), "addTimeToDateString")
      setIsBookingTime(addTimeToDateString(timeSlot))
    }
  }, [])
  //combine the date and time
  useEffect(() => {
    if (isBookingDate && isBookingTime) {
      const [hours, minutes, seconds] = isBookingTime.split(":")
      const combinedDateTime = moment
        .utc(isBookingDate)
        .set({
          hour: parseInt(hours, 10),
          minute: parseInt(minutes, 10),
          second: 0,
          millisecond: 0,
        })
        .toISOString()

      console.log(combinedDateTime, data)
      booking({ target: { value: combinedDateTime, name: "bookingDate" } })
    }
  }, [isBookingTime, isBookingDate])
  // Time Code (END)

  // Collection Booking Data Code (START)
  const booking = (event, index = null, info = "") => {
    const { name, value, type, lang } = event.target

    console.log("index", index, "name", name, "value", value, event)

    if (index !== null) {
      let updatedBooking

      if (name === "vehicle") {
        const fixedPricingTypes = [
          "car",
          "suv5",
          "pickupTruck",
          "suv7",
          "commercialVehicle",
        ]

        updatedBooking = isData.booking.map((item, i) =>
          i === index
            ? {
                ...item,
                service: {
                  ...item.service,
                  vehicleType: value,
                  serviceName: "",
                  price: 0,
                },
                pricingType: fixedPricingTypes.includes(value)
                  ? "fixed"
                  : "rate",
                totalLength: 1,
                addonService: [],
                totalPrice: 0,
              }
            : item
        )
      } else if (name === "serviceType") {
        updatedBooking = isData.booking.map((item, i) =>
          i === index
            ? {
                ...item,
                service: {
                  ...item.service,
                  serviceName: value,
                  serviceDesc: "",
                  price: 0,
                },
                addonService: [],
                addonTotalPrice: 0,
                totalPrice: 0,
                servicePrice: 0,
              }
            : item
        )
      } else if (name === "service") {
        const selectedOption = event.target.options[event.target.selectedIndex]
        const description = selectedOption.getAttribute("data-description")
        const servicePrice = parseInt(
          selectedOption.getAttribute("data-price"),
          10
        )

        updatedBooking = isData.booking.map((item, i) =>
          i === index
            ? {
                ...item,
                service: {
                  ...item.service,
                  serviceId: parseInt(value),
                  serviceDesc: description,
                  price: servicePrice,
                },
                addonService: [],
                addonTotalPrice: 0,
                totalPrice: servicePrice,
                servicePrice: servicePrice,
              }
            : item
        )
      } else if (name === "addonService") {
        // const selectedAddons = Array.from(event.target.options)
        //   .filter((option) => option.selected)
        //   .map((option) => ({
        //     addonServiceId: parseInt(option.value, 10), // Assuming addonServiceId is an integer
        //     addonSericeName: option.getAttribute("addon-data-description"), // Assuming addonServiceName is the text of the option
        //     addonPrice: parseInt(option.getAttribute("addon-data-price"), 10), // You need to fetch addon price based on the selection
        //   }))
        const selectedAddons = value

        const addonServiceTotal = selectedAddons.reduce(
          (total, addon) => total + addon.addonPrice,
          0
        )

        updatedBooking = isData.booking.map((item, i) =>
          i === index
            ? {
                ...item,
                addonService: selectedAddons,
                addonTotalPrice: addonServiceTotal,
                totalPrice: addonServiceTotal + item.service.price,
                servicePrice: item.service.price,
              }
            : item
        )
      } else if (name === "servicePrice") {
        updatedBooking = isData.booking.map((item, i) =>
          i === index
            ? {
                ...item,
                service: {
                  ...item.service,
                  price: parseInt(value, 10),
                },
                servicePrice: parseInt(value, 10),
                totalPrice: parseInt(value, 10) + item.addonTotalPrice,
              }
            : item
        )
        // setIsData((prevData) => ({
        //   ...prevData,
        //   addonService: selectedAddons,
        // }))
      } else if (name === "addonTotalPrice") {
        updatedBooking = isData.booking.map((item, i) =>
          i === index
            ? {
                ...item,
                addonTotalPrice: parseInt(value, 10),
                totalPrice: parseInt(value, 10) + item.service.price,
              }
            : item
        )
        // setIsData((prevData) => ({
        //   ...prevData,
        //   addonService: selectedAddons,
        // }))
      } else if (name === "totalLength") {
        updatedBooking = isData.booking.map((item, i) =>
          i === index
            ? {
                ...item,
                totalLength: parseInt(value, 10),
                servicePrice: parseInt(value, 10) * item.service.price,
                totalPrice:
                  parseInt(value, 10) * item.service.price +
                  item.addonTotalPrice,
              }
            : item
        )
      }

      const addonServiceTotal = updatedBooking.reduce(
        (total, booking) => total + booking.addonTotalPrice,
        0
      )
      const serviceTotal = updatedBooking.reduce(
        (total, booking) => total + booking.servicePrice,
        0
      )

      console.log(serviceTotal, "price", addonServiceTotal)
      console.log("updatedBooking========>", updatedBooking)

      calculatePricing({ target: { value: serviceTotal } }, "serviceTotal")
      calculatePricing(
        { target: { value: addonServiceTotal } },
        "addonServiceTotal"
      )

      setIsData((prevData) => ({
        ...prevData,
        booking: updatedBooking,
        addonServiceTotal,
        serviceTotal,
      }))
    } else if (name === "bookingTimeSlot") {
      setIsData((prevData) => ({
        ...prevData,
        [name]: parseInt(value, 10),
      }))
    } else if (name === "bookingDate") {
      setIsData((prevData) => ({
        ...prevData,
        [name]: value,
      }))
    } else if (type === "number" || lang === "number") {
      setIsData((prevData) => ({
        ...prevData,
        [name]: parseInt(value, 10),
      }))
    } else if (lang === "boolean") {
      setIsData((prevData) => ({
        ...prevData,
        [name]: value === "true",
      }))
    } else {
      console.log("elese")
      setIsData((prevData) => ({
        ...prevData,
        [name]: value,
      }))
    }
    if (isData.email === "" && name === "location") {
      setIsData((prevData) => ({
        ...prevData,
        email: "info@boostmobiledetailing.ca",
      }))
    }
  }
  const handleAddonServiceChange = (e, index) => {
    const selectedIds = e.value // Array of selected addonServiceIds

    // Find the correct data context based on the index
    const bookingData = isData.booking[index]

    // Fetch all possible addon services based on the current selection criteria
    const allAddons =
      AppointmentData.find(
        (location) => location.locationRole === upperCase(isData?.locationType)
      )
        ?.staff.find(
          (staff) => staff.type === bookingData?.service?.vehicleType
        )
        ?.servicesList.find(
          (service) => service.type === bookingData?.service?.serviceName
        )
        ?.services.find(
          (service) => service?.id === bookingData?.service?.serviceId
        )?.addonService ?? []

    // Map selected addon IDs to their corresponding details
    const selectedAddons = selectedIds.map((id) => {
      const addon = allAddons.find((addon) => addon.id === id)
      return {
        addonServiceId: addon.id,
        addonServiceName: addon.serviceDisplayName,
        addonPrice: addon.price,
      }
    })

    const event = {
      target: {
        name: "addonService",
        value: selectedAddons,
      },
    }
    console.log(selectedAddons, "selectedAddonArray", event)
    booking(event, index)
  }
  const handleAddressSelect = (address) => {
    if (isData.location !== address) {
      console.log("Selected Address: ", address)
      booking({ target: { name: "location", value: address ?? "" } })
    }
  }
  // Collection Booking Data Code (End)

  //All Calculation
  const calculatePricing = (e, name) => {
    if (name === "isElectricityAvailable") {
      const electricityValue = e.target.value === "false" ? 15 : 0
      console.log(
        "consoling",
        typeof e.target.value,
        name,
        electricityValue,
        e.target.value
      )

      setIsData((prevData) => {
        const subTotal = prevData.subTotal ?? 0
        const taxAmount = prevData.taxAmount ?? 0
        const addonTotalPrice = prevData.addonTotalPrice ?? 0
        const couponDiscountPercentage = prevData.couponDiscountAmount ?? 0
        const giftCardDiscountAmount = prevData.giftCardDiscountAmount ?? 0

        const couponDiscountAmount = (subTotal * couponDiscountPercentage) / 100

        const totalAmount =
          subTotal +
          taxAmount +
          addonTotalPrice -
          couponDiscountAmount -
          giftCardDiscountAmount +
          electricityValue

        return {
          ...prevData,
          isElectricityAvailable: e.target.value === "true",
          electricityBill: electricityValue,
          totalAmount,
        }
      })
    } else if (name === "serviceTotal") {
      const serviceTotalNew = parseFloat(e.target.value)
      setIsData((prev) => {
        const newServiceTotal = serviceTotalNew
        const newSubTotal = newServiceTotal + prev.addonServiceTotal

        const couponDiscountPercentage = prev.couponDiscountAmount ?? 0
        const giftCardDiscountAmount = prev.giftCardDiscountAmount ?? 0
        const electricityBill = prev.electricityBill ?? 0
        const taxRate = prev.tax ?? 0

        // Calculate the coupon discount amount based on the percentage
        const couponDiscountAmount =
          (newSubTotal * couponDiscountPercentage) / 100

        // Calculate the new tax amount
        const newTaxAmount = Number(
          (
            ((newSubTotal - couponDiscountAmount - giftCardDiscountAmount) *
              taxRate) /
            100
          ).toFixed(2)
        )

        // Calculate the new total amount
        const newTotalAmount = (
          newSubTotal +
          newTaxAmount -
          couponDiscountAmount -
          giftCardDiscountAmount +
          electricityBill
        ).toFixed(2)

        return {
          ...prev,
          serviceTotal: newServiceTotal,
          subTotal: newSubTotal,
          taxAmount: newTaxAmount,
          totalAmount: Number(newTotalAmount),
        }
      })
    } else if (name === "addonServiceTotal") {
      const addonTotalPriceNew = parseFloat(e.target.value)
      setIsData((prev) => {
        const newAddonTotalPrice = addonTotalPriceNew
        const newSubTotal = prev.serviceTotal + newAddonTotalPrice

        const couponDiscountPercentage = prev.couponDiscountAmount ?? 0
        const giftCardDiscountAmount = prev.giftCardDiscountAmount ?? 0
        const electricityBill = prev.electricityBill ?? 0
        const taxRate = prev.tax ?? 0

        // Calculate the coupon discount amount based on the percentage
        const couponDiscountAmount =
          (newSubTotal * couponDiscountPercentage) / 100

        // Calculate the new tax amount
        const newTaxAmount = Number(
          (
            ((newSubTotal - couponDiscountAmount - giftCardDiscountAmount) *
              taxRate) /
            100
          ).toFixed(2)
        )

        // Calculate the new total amount
        const newTotalAmount = (
          newSubTotal +
          newTaxAmount -
          couponDiscountAmount -
          giftCardDiscountAmount +
          electricityBill
        ).toFixed(2)

        return {
          ...prev,
          addonServiceTotal: newAddonTotalPrice,
          subTotal: newSubTotal,
          taxAmount: newTaxAmount,
          totalAmount: Number(newTotalAmount),
        }
      })
    } else if (name === "couponDiscountAmount") {
      const newCouponDiscountPercentage = parseFloat(e.target.value)
      setIsData((prev) => {
        const couponDiscountAmount =
          (prev.subTotal * newCouponDiscountPercentage) / 100
        const newTaxAmount = Number(
          (
            ((prev.subTotal -
              couponDiscountAmount -
              prev.giftCardDiscountAmount) *
              prev.tax) /
            100
          ).toFixed(2)
        )
        const newTotalAmount = (
          prev.subTotal +
          newTaxAmount -
          couponDiscountAmount -
          prev.giftCardDiscountAmount +
          prev.electricityBill
        ).toFixed(2)

        return {
          ...prev,
          couponDiscountAmount: newCouponDiscountPercentage,
          totalAmount: Number(newTotalAmount),
          taxAmount: newTaxAmount,
        }
      })
    } else if (name === "giftCardDiscountAmount") {
      const newGiftCardDiscountAmount = parseFloat(e.target.value)
      setIsData((prev) => {
        // Convert couponDiscountAmount from percentage to absolute amount
        const couponDiscountAmount =
          (prev.subTotal * prev.couponDiscountAmount) / 100

        const newTaxAmount = Number(
          (
            ((prev.subTotal -
              couponDiscountAmount -
              newGiftCardDiscountAmount) *
              prev.tax) /
            100
          ).toFixed(2)
        )

        const newTotalAmount = (
          prev.subTotal +
          newTaxAmount -
          couponDiscountAmount -
          newGiftCardDiscountAmount +
          prev.electricityBill
        ).toFixed(2)

        return {
          ...prev,
          giftCardDiscountAmount: newGiftCardDiscountAmount,
          totalAmount: Number(newTotalAmount),
          taxAmount: newTaxAmount,
        }
      })
    } else if (name === "tax") {
      const newTax = parseInt(e.target.value)
      setIsData((prev) => {
        // Convert couponDiscountAmount from percentage to absolute amount
        const couponDiscountAmount =
          (prev.subTotal * prev.couponDiscountAmount) / 100

        const newTaxAmount = Number(
          (
            ((prev.subTotal -
              couponDiscountAmount -
              prev.giftCardDiscountAmount) *
              newTax) /
            100
          ).toFixed(2)
        )

        const newTotalAmount = Number(
          (
            prev.subTotal +
            newTaxAmount -
            couponDiscountAmount -
            prev.giftCardDiscountAmount +
            prev.electricityBill
          ).toFixed(2)
        )

        return {
          ...prev,
          tax: newTax,
          taxAmount: newTaxAmount,
          totalAmount: newTotalAmount,
        }
      })
    } else {
      console.log("Select a valid option !")
    }
  }

  useEffect(() => {
    console.log(isEdit, "data=====", isData)
  }, [isData])

  const removeBooking = (indexToRemove) => {
    const updatedBooking = isData.booking.filter(
      (_, index) => index !== indexToRemove
    )

    // Calculate new serviceTotal and addonServiceTotal
    const newServiceTotal = updatedBooking.reduce(
      (total, booking) => total + booking.servicePrice,
      0
    )
    const newAddonTotalPrice = updatedBooking.reduce(
      (total, booking) => total + booking.addonTotalPrice,
      0
    )

    // Calculate the coupon discount amount based on the percentage
    const couponDiscountAmount =
      (newServiceTotal * isData.couponDiscountAmount) / 100

    // Calculate new tax amount
    const newTaxAmount = Number(
      (
        ((newServiceTotal +
          newAddonTotalPrice -
          couponDiscountAmount -
          isData.giftCardDiscountAmount) *
          isData.tax) /
        100
      ).toFixed(2)
    )

    // Calculate new total amount
    const newTotalAmount = Number(
      (
        newServiceTotal +
        newAddonTotalPrice +
        newTaxAmount -
        couponDiscountAmount -
        isData.giftCardDiscountAmount +
        isData.electricityBill
      ).toFixed(2)
    )

    setIsData((prevData) => ({
      ...prevData,
      booking: updatedBooking,
      serviceTotal: newServiceTotal,
      addonServiceTotal: newAddonTotalPrice,
      subTotal: newServiceTotal + newAddonTotalPrice,
      taxAmount: newTaxAmount,
      totalAmount: newTotalAmount,
    }))
  }

  const BookingStatus = [
    {
      status: "confirmed",
    },
    {
      status: "processing",
    },
    {
      status: "cancelled",
    },
    {
      status: "completed",
    },
  ]
  // Custom template function to include both service name and price
  const customTemplate = (data) => (
    <div className="space-x-2">
      <span className="service-name">{data.serviceDisplayName}</span>
      <span className="service-price">${data.price.toFixed(2)}</span>
    </div>
  )

  useEffect(() => {
    console.log(customerListData, "customerListData")
  }, [])
  const customerFields = { value: "phoneNumber", groupBy: "firstName" }
  const filterData = ["Contains", "StartsWith", "EndsWith"]
  const [filterType, setFilterType] = useState("Contains")
  //set the value to header template
  const headerTemplate = () => {
    return (
      <div className="header">
        {" "}
        <span>Photo</span> <span className="columnHeader">Employee Info</span>
      </div>
    )
  }
  //set the value to item template
  const itemTemplate = (data) => {
    if (!data || !data.phoneNumber || !data.firstName) {
      return null
    }
    return (
      <div className="autocomplete-item overflow-hidden border-y-1 border-yellow-dark flex flex-col">
        <div className="flex flex-row">
          <span>Name:</span>
          <span>
            {data.firstName} ({data.phoneNumber})
          </span>
        </div>
      </div>
    )
  }

  // Function to find customer by phone number
  const findCustomerByPhoneNumber = (phoneNumber) => {
    return customerListData?.find(
      (customer) => customer?.phoneNumber === phoneNumber
    )
  }
  const onChange = (args) => {
    console.log(args.target.value)
    // Remove +1, special characters, symbols, and spaces from the phone number
    const cleanedValue = args.target.value.replace(/\+1|[^0-9]/g, '');
    console.log(findCustomerByPhoneNumber(cleanedValue))
    const customer = findCustomerByPhoneNumber(cleanedValue)

    if (customer) {
      booking({
        target: { name: "firstName", value: customer?.firstName ?? "" },
      })
      booking({ target: { name: "email", value: customer?.email ?? "" } })
      booking({
        target: { name: "phoneNumber", value: customer?.phoneNumber ?? "" },
      })
      booking({ target: { name: "location", value: customer?.address ?? "" } })
    } else {
      booking({
        target: { name: "phoneNumber", value: cleanedValue ?? "" },
      })
    }
  }

  return (
    <>
      <div className="fixed inset-0 z-30 bg-black bg-opacity-45 backdrop-blur-sm">
        <div className="flex h-full justify-center items-center">
          <div className="bg-white lg:h-[95%] auto h-[80%] lg:w-[75%] w-[95%] rounded flex flex-col relative">
            <div
              className="flex justify-end -mt-2 -mr-2 cursor-pointer"
              onClick={onClick}
            >
              <svg
                className="size-6 bg-white rounded border border-black"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>
            <div className="px-[5%] py-5 overflow-y-scroll">
              <h1>Informations:</h1>
              <div className="flex flex-col items-start justify-between h-[42rem]">
                <form
                  onSubmit={handleSubmit}
                  className="sm:space-y-0 space-y-5 mx-auto grid-flow-row sm:grid lg:grid-cols-7 gap-4 py-4 w-full"
                >
                  {/* Select Location: */}
                  <div className="flex flex-col col-span-2">
                    <span className="text-sm">
                      Select Location:
                      <span className="text-red-500">*</span>
                    </span>
                    <select
                      name="locationType"
                      id="location"
                      className="h-10 rounded-md px-2 text-black border border-black"
                      value={isData.locationType}
                      onChange={booking}
                      required
                    >
                      <option value="">Select Location</option>
                      {AppointmentData.map((item) => (
                        <option
                          key={item.locationRole}
                          value={lowerCase(item.locationRole)}
                        >
                          {item.displayName}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* Booking Status: */}
                  <div className="flex flex-col">
                    <span className="text-sm">
                      Booking Status:
                      <span className="text-red-500">*</span>
                    </span>
                    <select
                      name="bookingStatus"
                      id="bookingStatus"
                      className="h-10 rounded-md px-2 text-black border border-black"
                      value={isData.bookingStatus}
                      onChange={booking}
                      required
                    >
                      {BookingStatus.map((item) => (
                        <option key={item.status} value={item.status}>
                          {capitalize(item.status)}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* Booking Date: */}
                  <div className="flex flex-col col-span-2">
                    <span className="text-sm">
                      Booking Date:
                      <span className="text-red-500">*</span>
                    </span>
                    <input
                      type="date"
                      className="h-10 rounded-md px-2 text-black border border-black"
                      required
                      value={formatDate(isBookingDate)}
                      name="bookingDate"
                      onChange={(e) => setIsBookingDate(e.target.value)}
                      // onChange={booking}
                      // min={isEdit ? "" : formatDate(Date())}
                    />
                  </div>
                  {/* Booking Slot: */}
                  <div className="flex flex-col col-span-2">
                    <span className="text-sm">
                      Booking Slot: <span className="text-red-500">*</span>
                    </span>
                    {/* <select
                      name="bookingSlot"
                      id="bookingSlot"
                      className="h-10 rounded-md px-2 text-black border border-black"
                      value={isData.bookingSlot}
                      lang="number"
                      onChange={booking}
                      required
                    >
                      <option value="">Select Slot</option>
                      {allBookingSlot.map((item) => (
                        <option
                          key={item.id}
                          value={item.id}
                          className={` ${
                            item.isSlot ? "bg-red-100" : "bg-green-100"
                          } `}
                        >
                          {moment(item.timeSlot).format("hh:mm A")}
                        </option>
                      ))}
                    </select> */}
                    <input
                      type="time"
                      id="bookingTimeSlot"
                      className="h-10 rounded-md px-2 text-black border border-black"
                      name="bookingTimeSlot"
                      // step="60"
                      value={isBookingTime}
                      onChange={(e) => setIsBookingTime(e.target.value)}
                      required
                    />
                  </div>
                  <hr className="col-span-7 bg-slate-400 h-[0.2rem]" />
                  <div className="flex flex-col col-span-7">
                    {isData.booking?.map((value, index) => {
                      return (
                        <div className="flex justify-between space-y-4">
                          <div className="flex lg: flex-col space-y-4 mb-4 w-full">
                            <div className="flex flex-wrap justify-between space-y-2 md:space-y-0">
                              <div className="flex flex-col w-[9rem]">
                                <span className="text-sm">
                                  Select Vehicle:
                                  <span className="text-red-500">*</span>
                                </span>
                                <select
                                  name="vehicle"
                                  id="vehicle"
                                  className="h-10 rounded-md px-2 text-black border border-black"
                                  value={value?.service?.vehicleType}
                                  onChange={(e) => booking(e, index)}
                                  required
                                  // disabled
                                >
                                  <option value="">Select Vehicle</option>
                                  {AppointmentData.find(
                                    (location) =>
                                      location.locationRole ===
                                      upperCase(isData?.locationType)
                                  )?.staff.map((staff) => (
                                    <option
                                      key={staff.type}
                                      value={staff.type}
                                      disabled={!staff.isAvailable}
                                    >
                                      {staff.staffDisplayName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="flex flex-col w-[16rem]">
                                <span className="text-sm">
                                  Select Service Type:
                                  <span className="text-red-500">*</span>
                                </span>
                                <select
                                  name="serviceType"
                                  id="serviceType"
                                  className="h-10 rounded-md px-2 text-black border border-black"
                                  value={
                                    isData.serviceType ??
                                    value?.service?.serviceName
                                  }
                                  onChange={(e) => booking(e, index)}
                                  required
                                >
                                  <option value="">Select Service Type</option>
                                  {AppointmentData.find(
                                    (location) =>
                                      location.locationRole ===
                                      upperCase(isData?.locationType)
                                  )
                                    ?.staff.find((staff) =>
                                      staff.type === value?.service?.vehicleType
                                        ? value?.service?.vehicleType
                                        : isData.vehicle
                                    )
                                    ?.servicesList.map((service) => (
                                      <option
                                        key={service.type}
                                        value={service.type}
                                      >
                                        {service.categoryDisplayName}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="flex flex-col w-[16rem]">
                                <span className="text-sm">
                                  Select Service:
                                  <span className="text-red-500">*</span>
                                </span>
                                <select
                                  name="service"
                                  id="service"
                                  className="h-10 rounded-md px-2 text-black border border-black"
                                  value={value?.service?.serviceId}
                                  onChange={(e) => booking(e, index)}
                                  required
                                >
                                  <option value="">Select Service</option>
                                  {AppointmentData.find(
                                    (location) =>
                                      location.locationRole ===
                                      upperCase(isData?.locationType)
                                  )
                                    ?.staff.find((staff) =>
                                      staff.type === value?.service?.vehicleType
                                        ? value?.service?.vehicleType
                                        : isData.vehicle
                                    )
                                    ?.servicesList.find((service) =>
                                      service.type ===
                                      value?.service?.serviceName
                                        ? value?.service?.serviceName
                                        : isData.serviceType
                                    )
                                    ?.services.map((service) => (
                                      <option
                                        key={service.id}
                                        value={service.id}
                                        data-description={
                                          service.serviceDisplayName
                                        }
                                        data-price={service.price}
                                      >
                                        {service.serviceDisplayName}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="flex flex-col w-[9rem]">
                                <span className="text-sm">
                                  Service Price:
                                  <span className="text-red-500">*</span>
                                </span>
                                <input
                                  type="number"
                                  className="h-10 rounded-md px-2 text-black border border-black"
                                  placeholder="$ 0.00"
                                  required
                                  min={0}
                                  value={
                                    isData.servicePrice ?? value?.service?.price
                                  }
                                  name="servicePrice"
                                  onChange={(e) => booking(e, index)}
                                />
                              </div>
                            </div>
                            <div className="flex flex-wrap md:space-x-4 space-y-2 md:space-y-0">
                              <div className="flex flex-col w-[18rem]">
                                <span className="text-sm">
                                  (Click Ctrl) Select Addon :
                                </span>
                                {/* <select
                                  name="addonService"
                                  id="addonService"
                                  className="md:h-16 py-2 space-y-1 rounded-md px-2 text-black border border-black"
                                  value={value?.addonService?.map(
                                    (addon) => addon.addonServiceId
                                  )}
                                  onChange={(e) => booking(e, index)}
                                  multiple
                                >
                                  {AppointmentData.find((location) =>
                                    location.locationRole === data?.locationType
                                      ? upperCase(data?.locationType)
                                      : isData?.locationType
                                  )
                                    ?.staff.find((staff) =>
                                      staff.type === value?.service?.vehicleType
                                        ? value?.service?.vehicleType
                                        : isData.vehicle
                                    )
                                    ?.servicesList.find((service) =>
                                      service.type ===
                                      value?.service?.serviceName
                                        ? value?.service?.serviceName
                                        : isData.serviceType
                                    )
                                    ?.services.find(
                                      (service) =>
                                        service?.id ===
                                          value?.service.serviceId ??
                                        isData.addonService
                                    )
                                    ?.addonService.map((addon) => (
                                      <option
                                        key={addon.id}
                                        value={addon.id}
                                        addon-data-price={addon.price}
                                        addon-data-description={
                                          addon.serviceDisplayName
                                        }
                                      >
                                        {addon.serviceDisplayName} $
                                        {addon.price}
                                      </option>
                                    ))}
                                </select> */}
                                <MultiSelectComponent
                                  id="addonService"
                                  name="addonService"
                                  placeholder="Choose Add-on Services"
                                  className="md:h-16 py-2 space-y-1 rounded-md px-2 text-black border border-black"
                                  // allowObjectBinding={true}
                                  // allowCustomValue={true}
                                  style={{ width: "100%" }}
                                  value={value?.addonService?.map(
                                    (addon) => addon.addonServiceId
                                  )}
                                  dataSource={
                                    AppointmentData.find(
                                      (location) =>
                                        location.locationRole ===
                                        upperCase(isData?.locationType)
                                    )
                                      ?.staff.find((staff) =>
                                        staff.type ===
                                        value?.service?.vehicleType
                                          ? value?.service?.vehicleType
                                          : isData.vehicle
                                      )
                                      ?.servicesList.find((service) =>
                                        service.type ===
                                        value?.service?.serviceName
                                          ? value?.service?.serviceName
                                          : isData.serviceType
                                      )
                                      ?.services.find(
                                        (service) =>
                                          service?.id ===
                                            value?.service.serviceId ??
                                          isData.addonService
                                      )?.addonService ?? []
                                  }
                                  fields={{
                                    text: "serviceDisplayName",
                                    value: "id",
                                  }}
                                  mode="CheckBox"
                                  change={(e) =>
                                    handleAddonServiceChange(e, index)
                                  }
                                  itemTemplate={customTemplate} // Use the custom template for rendering
                                >
                                  <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                              </div>
                              <div className="flex flex-col w-[9rem]">
                                <span className="text-sm">
                                  Enter Addon Price:
                                  {/* <span className="text-red-500">*</span> */}
                                </span>
                                <input
                                  type="number"
                                  className="h-10 rounded-md px-2 text-black border border-black"
                                  placeholder="$ 0.00"
                                  min={0}
                                  required
                                  value={value?.addonTotalPrice ?? "$ 0.00"}
                                  name="addonTotalPrice"
                                  onChange={(e) => booking(e, index)}
                                />
                              </div>
                              <div className="flex flex-col w-[9rem]">
                                <span className="text-sm">
                                  Enter SubTotal Price:
                                  {/* <span className="text-red-500">*</span> */}
                                </span>
                                <input
                                  type="number"
                                  className="h-10 rounded-md px-2 text-black border border-black"
                                  placeholder="$ 0.00"
                                  min={0}
                                  required
                                  value={value.totalPrice}
                                  name="totalPrice"
                                  onChange={(e) => booking(e, index)}
                                  disabled
                                />
                              </div>
                              <div className="flex flex-col w-[9rem]">
                                {value.pricingType === "rate" ? (
                                  <>
                                    <span className="text-sm">
                                      Lenght/ No of Seats:
                                      <span className="text-red-500">*</span>
                                    </span>
                                    <input
                                      type="number"
                                      className="h-10 rounded-md px-2 text-black border border-black"
                                      placeholder="0"
                                      min={0}
                                      required
                                      value={value.totalLength}
                                      name="totalLength"
                                      onChange={(e) => booking(e, index)}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <hr />
                          </div>
                          <div
                            className="flex justify-end items-center bg-red-100 h-40 cursor-pointer ml-1"
                            onClick={() => {
                              removeBooking(index)
                            }}
                          >
                            <svg
                              className="size-6 text-red-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  {/* Select for isWaterAvailable */}
                  <div className="flex flex-col col-span-2">
                    <label htmlFor="isWaterAvailable" className="text-sm">
                      Water Availability:<span className="text-red-500">*</span>
                    </label>
                    <select
                      id="isWaterAvailable"
                      name="isWaterAvailable"
                      value={isData.isWaterAvailable} // Ensure to convert boolean to string
                      onChange={booking}
                      className="h-10 rounded-md px-2 text-black border border-black"
                      lang="boolean"
                      disabled={isData.locationType !== "mobile"}
                      required
                    >
                      <option value="">Select W-Access</option>
                      <option value={true}>Not - Sure</option>
                      <option value={false}>Not Available</option>
                    </select>
                  </div>

                  {/* Select for isElectricityAvailable */}
                  <div className="flex flex-col col-span-2">
                    <label htmlFor="isElectricityAvailable" className="text-sm">
                      Electricity Availability:
                      <span className="text-red-500">*</span>
                    </label>
                    <select
                      id="isElectricityAvailable"
                      name="isElectricityAvailable"
                      value={isData.isElectricityAvailable} // Ensure to convert boolean to string
                      onChange={(e) => {
                        calculatePricing(e, "isElectricityAvailable")
                      }}
                      className="h-10 rounded-md px-2 text-black border border-black"
                      lang="boolean"
                      disabled={isData.locationType !== "mobile"}
                      required
                    >
                      <option value="">Select E-Access</option>
                      <option value={true}>Not - Sure</option>
                      <option value={false}>Not Available</option>
                    </select>
                  </div>

                  {/* Service Total */}
                  <div className="flex flex-col">
                    <span className="text-sm">Service Total:</span>
                    <input
                      type="number"
                      className="h-10 rounded-md px-2 text-black border border-black"
                      placeholder="Service Total"
                      value={isData.serviceTotal}
                      min={0}
                      name="serviceTotal"
                      onChange={(e) => {
                        calculatePricing(e, "serviceTotal")
                      }}
                    />
                  </div>

                  {/* Addon Service Total */}
                  <div className="flex flex-col">
                    <span className="text-sm">Addon Total:</span>
                    <input
                      type="number"
                      className="h-10 rounded-md px-2 text-black border border-black"
                      placeholder="Addon Service Total"
                      value={isData.addonServiceTotal}
                      min={0}
                      name="addonServiceTotal"
                      onChange={(e) => {
                        calculatePricing(e, "addonServiceTotal")
                      }}
                    />
                  </div>

                  {/* Subtotal */}
                  <div className="flex flex-col">
                    <span className="text-sm">Subtotal:</span>
                    <input
                      type="number"
                      step="0.01"
                      className="h-10 rounded-md px-2 text-black border border-black"
                      placeholder="Subtotal"
                      value={isData.subTotal}
                      min={0}
                      name="subTotal"
                      onChange={booking}
                      disabled
                    />
                  </div>

                  {/* Coupon Discount Amount */}
                  <div className="flex flex-col">
                    <span className="text-sm">Coupon Discount %:</span>
                    <input
                      type="number"
                      step="0.01"
                      className="h-10 rounded-md px-2 text-black border border-black"
                      placeholder="Coupon Discount Amount"
                      min={0}
                      value={isData.couponDiscountAmount}
                      name="couponDiscountAmount"
                      onChange={(e) => {
                        calculatePricing(e, "couponDiscountAmount")
                      }}
                    />
                  </div>

                  {/* Gift Card Discount Amount */}
                  <div className="flex flex-col">
                    <span className="text-sm">Gift Card Amount:</span>
                    <input
                      type="number"
                      step="0.01"
                      className="h-10 rounded-md px-2 text-black border border-black"
                      placeholder="Gift Card Discount Amount"
                      value={isData.giftCardDiscountAmount}
                      min={0}
                      name="giftCardDiscountAmount"
                      onChange={(e) => {
                        calculatePricing(e, "giftCardDiscountAmount")
                      }}
                    />
                  </div>

                  {/* Tax */}
                  <div className="flex flex-col">
                    <span className="text-sm">
                      Enter TAX:
                      <span className="text-red-500">*</span>
                    </span>
                    <input
                      type="number"
                      className="h-10 rounded-md px-2 text-black border border-black"
                      placeholder="tax"
                      min={0}
                      required
                      value={isData.tax ?? 5}
                      name="tax"
                      onChange={(e) => {
                        calculatePricing(e, "tax")
                      }}
                      disabled
                    />
                  </div>

                  {/* Tax Amount */}
                  <div className="flex flex-col">
                    <span className="text-sm">Tax Amount:</span>
                    <input
                      type="number"
                      step="0.01"
                      className="h-10 rounded-md px-2 text-black border border-black"
                      placeholder="Tax Amount"
                      value={isData.taxAmount}
                      name="taxAmount"
                      onChange={booking}
                      disabled
                    />
                  </div>

                  {/* Electricity Bill */}
                  <div className="flex flex-col">
                    <span className="text-sm">Electricity Bill:</span>
                    <input
                      type="number"
                      className="h-10 rounded-md px-2 text-black border border-black"
                      placeholder="Electricity Bill"
                      value={isData.electricityBill}
                      min={0}
                      name="electricityBill"
                      onChange={booking}
                      disabled
                    />
                  </div>

                  {/* Total Amount */}
                  <div className="flex flex-col col-span-2">
                    <span className="text-sm">Total Amount:</span>
                    <input
                      type="number"
                      step="0.01"
                      className="h-10 rounded-md px-2 text-black border border-black"
                      placeholder="Total Amount"
                      value={isData.totalAmount}
                      min={0}
                      name="totalAmount"
                      onChange={booking}
                      disabled
                    />
                  </div>

                  <div className="flex flex-col justify-center col-span-7">
                    {isData.booking?.length < 2 && (
                      <span
                        className="h-10 w-[15rem] rounded-md px-2 text-white border border-y-yellow-light bg-yellow-light_medium shadow shadow-black flex items-center justify-center cursor-pointer"
                        onClick={() => {
                          setIsData((prevData) => ({
                            ...prevData,
                            booking: [...isData.booking, 1],
                          }))
                        }}
                      >
                        Add Another Car
                      </span>
                    )}
                  </div>

                  {/* Enter Name: */}
                  <div className="flex flex-col col-span- 2">
                    <span className="text-sm">
                      Enter Full Name:
                      <span className="text-red-500">*</span>
                    </span>
                    <input
                      type="text"
                      className="h-10 rounded-md px-2 text-black border border-black"
                      placeholder="Full Name"
                      required
                      value={isData.firstName}
                      name="firstName"
                      onChange={booking}
                    />
                  </div>
                  {/* Enter Email: */}
                  <div className="flex flex-col col-span-2">
                    <span className="text-sm">
                      Enter Email:
                      <span className="text-red-500">*</span>
                    </span>
                    <input
                      type="email"
                      className="h-10 rounded-md px-2 text-black border border-black"
                      placeholder="Enter Email"
                      value={isData.email}
                      name="email"
                      onChange={booking}
                    />
                    <div className="space-x-2">
                      <input
                        className="mt-1"
                        type="checkbox"
                        name="sendEmail"
                        id="sendEmail"
                        checked={isSendStatus.sendEmail}
                        onChange={(e) => {
                          setIsSendStatus((item) => ({
                            ...item,
                            sendEmail: e.target.checked,
                          }))
                        }}
                      />
                      <label htmlFor="sendEmail">Send Email?</label>
                    </div>
                  </div>
                  {/* Enter Phone Number: */}
                  <div className="flex flex-col col-span-2">
                    <span className="text-sm">
                      Enter Phone Number:
                      <span className="text-red-500">*</span>
                    </span>
                    {/* <input
                      type="number"
                      className="h-10 rounded-md px-2 text-black border border-black"
                      placeholder="Ph.(+1 236-992-8610)"
                      value={isData.phoneNumber}
                      required
                      name="phoneNumber"
                      onChange={booking}
                    /> */}
                    <div className="h-11">
                      <AutoCompleteComponent
                        id="phoneNumber"
                        dataSource={customerListData ?? []}
                        // sortOrder="Ascending"
                        placeholder="Ph.(+1 236-992-8610)"
                        value={isData.phoneNumber}
                        fields={customerFields ?? {}}
                        // showPopupButton={true}
                        highlight={true}
                        filterType={filterType}
                        popupHeight="250px"
                        suggestionCount={8}
                        onChange={onChange}
                        // itemTemplate={itemTemplate}
                        // headerTemplate={headerTemplate}
                      />
                    </div>
                    <div className="space-x-2">
                      <input
                        className="mt-1"
                        type="checkbox"
                        name="sendMsg"
                        id="sendMsg"
                        checked={isSendStatus.sendMsg}
                        onChange={(e) => {
                          setIsSendStatus((item) => ({
                            ...item,
                            sendMsg: e.target.checked,
                          }))
                        }}
                      />
                      <label htmlFor="sendMsg">Send Msg?</label>
                    </div>
                  </div>
                  {/* Enter Address: */}
                  <div className="flex flex-col col-span-2">
                    {isData.locationType === "mobile" ? (
                      <>
                        <span className="text-sm">
                          Enter Address:
                          <span className="text-red-500">*</span>
                        </span>
                        <GoogleMap
                          onAddressSelect={handleAddressSelect}
                          data={isData.location}
                        />
                        {/* <input
                          type="text"
                          className="h-10 rounded-md px-2 text-black border border-black"
                          placeholder="Address"
                          value={isData.location}
                          name="location"
                          onChange={booking}
                          disabled={isData.locationType !== "mobile"}
                        /> */}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* Button */}
                  <div className="flex col-span-7 space-x-5 justify-end">
                    <button
                      className="bg-gray-300 py-2 w-28 rounded shadow shadow-black"
                      onClick={onClick}
                    >
                      Cancel
                    </button>
                    {isEdit ? (
                      <>
                        <button
                          className="bg-yellow-medium_dark py-2 w-28 rounded shadow shadow-black"
                          type="submit"
                        >
                          Save
                        </button>
                        <button
                          className="bg-yellow-medium_dark py-2 w-28 rounded shadow shadow-black text-white"
                          type="button"
                          onClick={() => {
                            handleCreateBookingApi()
                          }}
                        >
                          Re-book
                        </button>
                      </>
                    ) : (
                      <button
                        className="bg-yellow-medium_dark py-2 w-28 rounded shadow shadow-black"
                        type="submit"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>

            {isLoading ? (
              <div className="absolute bg-black inset-0 bg-opacity-20 backdrop-blur-sm flex justify-center items-center text-white flex-col">
                <img src={Loader} alt="" className="size-14 object-cover" />
                <span>Loading...</span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateBooking
