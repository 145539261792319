import React, { useEffect, useState } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { FiSettings } from "react-icons/fi"
import { TooltipComponent } from "@syncfusion/ej2-react-popups"
import { useStateContext } from "../components/contexts/ContextProvider"
import { Provider, useSelector } from "react-redux"
import store from "../components/store/Store"

import { Navbar, Footer, Sidebar, ThemeSettings } from "../components/UI"
import {
  Dashboard,
  Orders,
  Calendar,
  Employees,
  Stacked,
  Pyramid,
  Customers,
  Line,
  Area,
  Bar,
  Pie,
  Financial,
  ColorMapping,
  Editor,
  Invoices,
} from "../components/pages"
import "./Style.css"
import Discount from "../components/pages/Discount"
import Marketing from "../components/pages/Marketing"
import Booking from "../components/pages/Booking"
import Workflow from "../components/pages/Workflow"
import Services from "../components/pages/Services"

const RouteIndex = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext()

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode")
    const currentThemeMode = localStorage.getItem("themeMode")
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor)
      setCurrentMode(currentThemeMode)
    }
  }, [])
  return (
    <div className={` ${currentMode === "Dark" ? "dark" : ""} `}>
      <div className="flex relative dark:bg-main-dark-bg">
        {/* <div className="fixed z-20 right-4 bottom-4">
          <TooltipComponent content="Settings" position="Top">
            <button
              type="button"
              onClick={() => setThemeSettings(true)}
              style={{ background: currentColor, borderRadius: "50%" }}
              className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
            >
              <FiSettings />
            </button>
          </TooltipComponent>
        </div> */}
        {activeMenu ? (
          <div className="w-72 z-20 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
            <Sidebar />
          </div>
        ) : (
          <div className="w-0 dark:bg-secondary-dark-bg">
            <Sidebar />
          </div>
        )}
        <div
          className={
            activeMenu
              ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  "
              : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
          }
        >
          <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
            <Navbar />
          </div>
          <div>
            {themeSettings && <ThemeSettings />}

            <Routes>
              {/* dashboard  */}
              {/* <Route path="/" element={<Dashboard />} /> */}
              <Route path="/overview" element={<Dashboard />} />

              {/* pages  */}
              <Route path="/bookings" element={<Booking />} />
              <Route path="/employees" element={<Employees />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/marketing" element={<Marketing />} />
              <Route path="/services" element={<Services />} />

              {/* apps  */}
              <Route path="/Workflow" element={<Workflow />} />
              <Route path="/Invoice" element={<Invoices />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/discount" element={<Discount />} />

              {/* charts  */}
              <Route path="/line" element={<Line />} />
              <Route path="/area" element={<Area />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/financial" element={<Financial />} />
              <Route path="/color-mapping" element={<ColorMapping />} />
              <Route path="/pyramid" element={<Pyramid />} />
              <Route path="/stacked" element={<Stacked />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default RouteIndex
