import React, { useEffect, useRef, useState } from "react"
import { Header } from "../UI"
import DataTable from "react-data-table-component"
import JoditEditor from "jodit-react"
import { useDispatch, useSelector } from "react-redux"
import {
  AutoCompleteComponent,
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns"
import { SendMsgEmail } from "../data/modal/SendMsgEmail"
import { openToastify } from "../store/actions/toastifyAction"
import { REDUX_TOASTIFY_DEFAULTS } from "../store/config"

const Marketing = () => {
  let { customerListData } = useSelector((state) => state.customerReducer)
  const editor = useRef(null)
  const [smsContent, setSmsContent] = useState("")
  const [isSenderData, setIsSenderData] = useState([])
  const [isloading, setIsloading] = useState(false)
  const [isData, setIsData] = useState({
    sendMsg: false,
    sendEmail: false,
    subject: "",
    message: "",
  })
  const dispatch = useDispatch()

  const handleNotification = (type, tittle, msg, to) => {
    const typeOfNotification = type //email or "sms"
    const subject = tittle
    const msgContent = msg
    const recipient = to
    // Call the SendMsgEmail function
    SendMsgEmail(typeOfNotification, subject, msgContent, recipient, dispatch)
  }

  const handleSend = () => {
    if (isData.subject === "") {
      dispatch(
        openToastify(
          "Subject is Mendatory",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
        )
      )
      return
    }
    if (isData.message === "") {
      dispatch(
        openToastify(
          "Message is Mendatory",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
        )
      )
      return
    }

    if (isSenderData.length) {
      dispatch(
        openToastify(
          "Donot Leave or Refresh the page",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_INFO()
        )
      )
      setIsloading(true)

      isSenderData.forEach((customer, index) => {
        if (isData.sendMsg) {
          handleNotification(
            "sms",
            isData.subject,
            smsContent,
            customer?.phoneNumber
          )
        }
        if (isData.sendEmail) {
          if (customer?.email) {
            handleNotification(
              "email",
              isData.subject,
              isData.message,
              customer?.email
            )
          }
        } else {
          console.log("set isData.sendMsg || isData.sendEmail")
          dispatch(
            openToastify(
              "Select Message and Sms",
              REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
            )
          )
        }
        setTimeout(() => {}, 20000) // 20 Sec

        if (isSenderData.length === index + 1) {
          setTimeout(() => {
            setIsloading(false)
          }, 2000)
        }
      })

      setIsData((prevData) => ({
        ...prevData,
        sendMsg: false,
        sendEmail: false,
        subject: "",
        message: "",
      }))
    } else {
      console.log("select receiver")
      dispatch(
        openToastify(
          "Sender Details are Mendatory",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
        )
      )
    }

    setTimeout(() => {
      setIsloading(false)
    }, 2000)
  }

  function removeHTMLTags(str) {
    return str.replace(/<\/?[^>]+(>|$)/g, "")
  }
  useEffect(() => {
    console.log(isData, "isData")
    if (isData.sendMsg) {
      const sms = removeHTMLTags(isData.message)
      setSmsContent(sms)
    }
  }, [isData])

  // Function to find customers by phone numbers
  const findCustomersByPhoneNumbers = (phoneNumbers) => {
    return customerListData?.filter((customer) =>
      phoneNumbers.includes(customer?.phoneNumber)
    )
  }
  const onChange = (args) => {
    console.log(args.target.value)
    const customers = findCustomersByPhoneNumbers(args.target.value)
    console.log("customers", customers)
    setIsSenderData(customers)
  }

  // Custom template function to include both service name and price
  const customTemplate = (data) => (
    <div className="space-x-2">
      <span className="email">{data.email}</span>
      <span className="phoneNumber">{data.phoneNumber}</span>
    </div>
  )
  return (
    <>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <Header category="Page" title="Marketing" />
        {/* <DataTable
          // title="Coupons List"
          columns={mainColumns}
          data={Users}
          pagination
          fixedHeader
          selectableRows
          fixedHeaderScrollHeight="480px"
        /> */}
        <div className="overflow-hidden">
          <MultiSelectComponent
            placeholder="Select Recipient"
            dataSource={customerListData ?? []}
            width={300}
            value={null}
            fields={{
              text: "phoneNumber",
              value: "phoneNumber",
            }}
            mode="CheckBox"
            showSelectAll={true}
            // showDropDownIcon={true}
            // enableSelectionOrder={true}
            popupHeight="350px"
            itemTemplate={customTemplate}
            onChange={onChange}
          >
            <Inject services={[CheckBoxSelection]} />
          </MultiSelectComponent>
        </div>

        <div className="mt-3">
          <div>
            <span>Subject</span>
            <textarea
              className="border border-gray-300 w-full p-1"
              name="subject"
              id="subject"
              value={isData.subject}
              placeholder="Enter the subject"
              onChange={(e) => {
                setIsData((prevData) => ({
                  ...prevData,
                  subject: e.target.value,
                }))
              }}
            ></textarea>
          </div>
          <JoditEditor
            ref={editor}
            value={isData.message}
            onChange={(newContent) => {
              setIsData((prevData) => ({
                ...prevData,
                message: newContent,
              }))
            }}
          />
        </div>

        <div className="mt-3 space-y-2">
          <div className="flex space-x-3">
            {/* Send Email */}
            <div className="space-x-2">
              <input
                className="mt-1"
                type="checkbox"
                name="sendEmail"
                id="sendEmail"
                checked={isData?.sendEmail}
                onChange={(e) => {
                  setIsData((item) => ({
                    ...item,
                    sendEmail: e.target.checked,
                  }))
                }}
              />
              <label htmlFor="sendEmail">Send Email?</label>
            </div>
            {/* Send SMS */}
            <div className="space-x-2">
              <input
                className="mt-1"
                type="checkbox"
                name="sendMsg"
                id="sendMsg"
                checked={isData?.sendMsg}
                onChange={(e) => {
                  setIsData((item) => ({
                    ...item,
                    sendMsg: e.target.checked,
                  }))
                }}
              />
              <label htmlFor="sendMsg">Send SMS?</label>
            </div>
          </div>
          <button
            className="flex bg-yellow-light py-1 px-4 items-center"
            onClick={() => {
              handleSend()
            }}
            disabled={isloading}
          >
            {isloading ? <span>SENDING...</span> : <span>SEND</span>}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  )
}

export default Marketing
