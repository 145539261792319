import React, { useEffect, useRef, useState } from "react"
import { Header } from "../UI"
import DataTable from "react-data-table-component"
import { capitalize, lowerCase, upperCase } from "lodash"
import moment from "moment"
import Loader from "../asserts/Spin.gif"
import { useDispatch, useSelector } from "react-redux"
import { deleteBookings, updateBookingStatus } from "../../axios/api/apiModule"
import { openToastify } from "../store/actions/toastifyAction"
import { REDUX_STORE_DEFAULTS, REDUX_TOASTIFY_DEFAULTS } from "../store/config"
import { bookingAction } from "../store/actions/bookingAction"
import { AppointmentData } from "../config/bookingDataConfig"
import ViewBooking from "../UI/ViewBooking"
import CreateBooking from "../UI/createBooking/CreateBooking"
import { SendMsgEmail } from "../data/modal/SendMsgEmail"

const Booking = () => {
  const [bookingData, setBookingData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEditData, setIsEditData] = useState(false)
  const [isForm, setIsForm] = useState(false)
  const [isViewBooking, setIsViewBooking] = useState(false)
  const [isFilterbyDate, setIsFilterbyDate] = useState(false)
  const [isData, setIsData] = useState()
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const dispatch = useDispatch()
  const [bookingDataType, setBookingDataType] = useState("all")
  const [selectedRows, setSelectedRows] = useState([])
  const [isSearch, setIsSearch] = useState()
  let { shopBookingData, mobileBookingData, isBookingUpdated } = useSelector(
    (state) => state.bookingReducer
  )
  const hasRunOnce = useRef(false)
  const changeData = (e) => {
    console.log(!mobileBookingData?.bookings)
    if (!mobileBookingData?.bookings || !shopBookingData?.bookings) {
      setBookingData([])
      return
    }

    setIsLoading(true)
    if (e === "shop") {
      setBookingDataType("shop")
      setBookingData(shopBookingData?.bookings)
    } else if (e === "mobile") {
      setBookingDataType("mobile")
      setBookingData(mobileBookingData?.bookings)
    } else {
      setBookingDataType("all")
      const sortedBookingData = [
        ...mobileBookingData?.bookings,
        ...shopBookingData?.bookings,
      ].sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt)
      })
      setBookingData(sortedBookingData)
    }
    setIsLoading(false)
    console.log("combinedData", bookingData)
  }

  useEffect(() => {
    if (!hasRunOnce) return

    changeData(bookingDataType)
    setTimeout(() => {
      // changeData("all")
    }, 2000)

    hasRunOnce.current = true
  }, [mobileBookingData?.bookings, shopBookingData?.bookings])

  const filterBookingDataByDate = () => {
    const filteredData = bookingData.filter((booking) => {
      const bookingDate = moment(booking.bookingDate)
      const start = startDate ? moment(startDate) : null
      const end = endDate ? moment(endDate) : null

      if (start && end) {
        return bookingDate.isBetween(start, end, "days", "[]")
      } else if (start) {
        return bookingDate.isSameOrAfter(start, "day")
      } else if (end) {
        return bookingDate.isSameOrBefore(end, "day")
      } else {
        return true
      }
    })
    setIsFilterbyDate(true)
    setBookingData(filteredData)
  }

  const filterBookingDataByBId = (input) => {
    console.log(input, "input")

    if (!input) {
      // If no input is provided, return all data
      changeData(bookingDataType)
      return
    }

    const isText = (value) => /^[a-zA-Z0-9@._\-+\s]+$/.test(value)
    const isPhoneNumber = (value) => /^[0-9]+$/.test(value) // Check if it's a number (digits only)

    // Filter data based on the input
    const filteredData = [
      ...mobileBookingData?.bookings,
      ...shopBookingData?.bookings,
    ].filter((booking) => {
      // Check if input matches an ID (partial or complete)
      if (booking.bId.includes(input)) {
        return true
      }
      // Check if input is a phone number
      else if (isPhoneNumber(input)) {
        return String(booking.phoneNumber).includes(input)
      }
      // Check if input is text (likely email)
      else if (isText(input)) {
        return booking.email.toLowerCase().includes(input.toLowerCase())
      }
      return false
    })

    console.log(filteredData, "filteredData")
    // Set the filtered data
    setBookingData(filteredData)
  }

  useEffect(() => {
    console.log(isSearch, "isSearch")
    setTimeout(() => {
      if (isSearch) {
        filterBookingDataByBId(isSearch)
      } else if (isSearch === "") {
        changeData(bookingDataType)
      }
    }, 1000)
  }, [isSearch])

  const hitDeleteBookingApi = async (id, locationType) => {
    try {
      const params = {
        locationType: locationType,
        bookingIds: id,
      }
      const response = await deleteBookings(params)
      console.log(response)
      if (response.success) {
        dispatch(
          openToastify(
            "Deleted Successfuly",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
          )
        )
        update()
      }
    } catch (error) {
      console.log(error)
      dispatch(
        openToastify(
          error?.response?.data?.msg,
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
        )
      )
    }
  }

  const handleSelectedRowsChange = (state) => {
    const ids = state.selectedRows.map((item) => {
      return item._id
    })
    console.log("SelectedRows", ids, state)
    setSelectedRows(ids)
  }

  const edit = (id) => {
    bookingData.map((booking) => {
      if (booking._id === id) {
        setIsData(booking)
      }
    })
    setIsForm(true)
    setIsEditData(true)
  }
  const closeEdit = () => {
    if (isForm) {
      setIsData()
      setIsForm(false)
    }
    setIsEditData(false)
    // changeData("all")
  }

  const view = (id) => {
    bookingData.map((booking) => {
      if (booking._id === id) {
        setIsData(booking)
      }
    })
    setIsViewBooking(true)
  }

  const closeView = () => {
    if (isViewBooking) {
      setIsViewBooking(false)
      setIsData()
    }
  }

  const remove = (ids, locationType) => {
    console.log(ids, "ids")
    hitDeleteBookingApi(ids, locationType)
  }

  // Change Booking Status (START)
  const BookingStatus = [
    {
      status: "confirmed",
    },
    {
      status: "processing",
    },
    {
      status: "cancelled",
    },
    {
      status: "completed",
    },
  ]
  const handleNotification = (type, tittle, msg, to) => {
    const typeOfNotification = type //email or "sms"
    const subject = tittle
    const msgContent = msg
    const recipient = to
    // Call the SendMsgEmail function
    SendMsgEmail(typeOfNotification, subject, msgContent, recipient, dispatch)
  }
  // Hit update booking Status Api
  const hitUpdateBookingStatusApi = async (params) => {
    try {
      const response = await updateBookingStatus(params)
      if (response.modifiedCount > 0) {
        dispatch(
          openToastify(
            "Booking Status Successfuly Update to " + params.updatedStatus,
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
          )
        )
        if (params.locationType === "mobile") {
          // For mobile bookings, update in mobile booking data
          const updatedMobileBookings = mobileBookingData?.bookings.map(
            (booking) =>
              booking._id === params.bookingIds[0]
                ? { ...booking, bookingStatus: params.updatedStatus }
                : booking
          )
          dispatch(
            bookingAction(REDUX_STORE_DEFAULTS.UPDATE_MOBILE_BOOKING_DATA(), {
              ...mobileBookingData,
              bookings: updatedMobileBookings,
            })
          )
        } else {
          // For mobile bookings, update in mobile booking data
          const updatedShopBookings = shopBookingData?.bookings.map((booking) =>
            booking._id === params.bookingIds[0]
              ? { ...booking, bookingStatus: params.updatedStatus }
              : booking
          )
          dispatch(
            bookingAction(REDUX_STORE_DEFAULTS.UPDATE_SHOP_BOOKING_DATA(), {
              ...shopBookingData,
              bookings: updatedShopBookings,
            })
          )
        }

        // sending nofication
        const booking = [
          ...mobileBookingData?.bookings,
          ...shopBookingData?.bookings,
        ].find(
          (booking) => booking._id === params.bookingIds[0] // or booking.bId, depending on your data structure
        )
        let msg = ""
        console.log(booking)

        if (BookingStatus[0].status === params.updatedStatus) {
          msg =
            "Hi " +
            booking.firstName +
            ", Your Booking with #ID " +
            booking.bId +
            " is Confirmed for " +
            moment(booking.bookingTimeSlot).format("YYYY-MM-DD hh:mm A") +
            " at " +
            ` ${
              booking.locationType === "mobile"
                ? booking.location
                : "Shop Location:- https://maps.app.goo.gl/i5WvFmkf7T2wxXTF9"
            }` +
            ", Please contact us at +1 236-992-8610"
        } else if (BookingStatus[1].status === params.updatedStatus) {
          msg =
            "Hi " +
            booking.firstName +
            ", Your Booking with #ID " +
            booking.bId +
            " is under process. Please wait until we confirm it or contact us after 2-3 hours at +1 236-992-8610 or email us at info@boostmobiledetailing.ca"
        } else if (BookingStatus[2].status === params.updatedStatus) {
          msg =
            "Hi " +
            booking.firstName +
            ", Your Booking with #ID " +
            booking.bId +
            " is Cancelled. For new booking or re-booking, Please contact us at +1 236-992-8610 or email us at info@boostmobiledetailing.ca"
        } else {
          msg =
            "Hi " +
            booking.firstName +
            ", Your Booking with #ID " +
            booking.bId +
            " is Completed. please Share your feedback or review on following https://g.page/r/CVvTQC_ki2l8EAI/review. For any further info Please contact us at +1 236-992-8610 or email us at info@boostmobiledetailing.ca"
        }

        if (booking?.bookingCnfMsg?.emailSend) {
          handleNotification(
            "email",
            "Booking Confirmation: Booking ID #" + booking.bId,
            msg,
            booking.email
          )
        }
        // Clear data
        setTimeout(() => {
          if (booking?.bookingCnfMsg?.msgSend) {
            handleNotification(
              "sms",
              "Booking Confirmation: Booking ID #" + booking.bId,
              msg,
              booking.phoneNumber
            )
          }
          setIsBookingStatus({
            bookingIds: [],
          })
        }, 1000)
      }
      console.log(response, "updateBookingStatus")
    } catch (error) {
      console.log(error)
    }
  }
  const [isBookingStatus, setIsBookingStatus] = useState({ bookingIds: [] })
  const handlebookingStatus = (event, locationType, id) => {
    const { name, value, type, lang } = event.target

    const params = {
      updatedStatus: value,
      locationType: locationType,
      bookingIds: [id],
    }
    console.log(name, value, params, isBookingStatus?.bookingIds)
    setIsBookingStatus(params)
    hitUpdateBookingStatusApi(params)
  }
  // Change Booking Status (END)

  //booking table
  const bookingGrid = [
    {
      name: "ID",
      selector: (row) => "#" + row.bId,
      sortable: true,
      // reorder: true,
    },
    {
      name: "Personal Info",
      // selector: (row) => moment(row.bookingTimeSlot).format("hh:mm A"),
      selector: (row) => (
        <div className="space-y-1 py-1 flex flex-col">
          <span>{row.firstName}</span>
          <a className="text-blue-600" href={`mailto:${row.email}`}>
            {row.email}
          </a>
          <a href={`tel:${row.phoneNumber}`}>{row.phoneNumber}</a>
          <span>{row.location}</span>
        </div>
      ),
      // sortable: true,
      // reorder: true,
    },
    {
      name: "Appointment Time",
      selector: (row) =>
        moment(row.bookingTimeSlot).utc().format("YYYY-MM-DD hh:mm A"),
      sortable: true,
      // reorder: true,
    },
    {
      name: "Booking Type",
      selector: (row) => capitalize(row.locationType),
      // sortable: true,
      // reorder: true,
    },
    {
      name: "Amount",
      selector: (row) => "$" + row.totalAmount.toFixed(2),
      sortable: true,
      reorder: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <div className="">
          <select
            name="bookingStatus"
            id="bookingStatus"
            className={` h-9 rounded-md px-2 text-black border border-black ${
              row._id === isBookingStatus?.bookingIds[0] &&
              isBookingStatus.updatedStatus &&
              "bg-slate-300"
            } ${
              row.bookingStatus === "processing" && "bg-yellow-500 text-white"
            } 
            ${row.bookingStatus === "cancelled" && "bg-red-600 text-white"}
            ${row.bookingStatus === "confirmed" && "bg-green-600 text-white"}
            ${row.bookingStatus === "completed" && "bg-orange-600 text-white"}
            `}
            value={
              row._id === isBookingStatus?.bookingIds[0]
                ? isBookingStatus.updatedStatus
                : row.bookingStatus
            }
            onChange={(e) => handlebookingStatus(e, row.locationType, row._id)}
            required
          >
            {BookingStatus.map((item) => (
              <option
                className="bg-white text-black"
                key={item.status}
                value={item.status}
              >
                {capitalize(item.status)}
              </option>
            ))}
          </select>
          {/* {row.bookingStatus === "processing" && (
            <span className="px-3 rounded-full bg-yellow-medium_dark">
              {capitalize(row.bookingStatus)}
            </span>
          )}
          {row.bookingStatus === "cancelled" && (
            <span className="px-3 rounded-full bg-red-600">
              {capitalize(row.bookingStatus)}
            </span>
          )}
          {row.bookingStatus === "confirmed" && (
            <span className="px-3 rounded-full bg-orange-600">
              {capitalize(row.bookingStatus)}
            </span>
          )}
          {row.bookingStatus === "completed" && (
            <span className="px-3 rounded-full bg-green-600">
              {capitalize(row.bookingStatus)}
            </span>
          )} */}
        </div>
      ),
      sortable: true,
      reorder: true,
    },
    {
      name: "Booked Time",
      selector: (row) => moment(row.createdAt).format("YYYY-MM-DD hh:mm A"),
      sortable: true,
      // reorder: true,
    },
    {
      name: "Notifications",
      selector: (row) => (
        <div className="flex flex-col space-y-1 text-center text-white">
          <span
            className={` px-3 rounded-full ${
              row.bookingCnfMsg?.emailSend ? "bg-green-600" : "bg-red-600"
            } `}
          >
            Email
          </span>
          <span
            className={` px-3 rounded-full ${
              row.bookingCnfMsg?.msgSend ? "bg-green-600" : "bg-red-600"
            } `}
          >
            SMS
          </span>
          {/* <span
            className={` px-3 rounded-full ${
              row.bookingCnfMsg?.eventAdded ? "bg-green-600" : "bg-red-600"
            } `}
          >
            G Event
          </span> */}
        </div>
      ),
      // sortable: true,
      // reorder: true,
    },
    {
      name: "Action",
      // selector: (row) => row.paid,
      cell: (row) => (
        <div className="flex bg-white space-x-1 border-2 shadow-sm shadow-gray-700 rounded-lg p-2">
          <svg
            onClick={() => view(row._id)}
            className="size-5 cursor-pointer text-green-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
          </svg>

          <svg
            onClick={() => edit(row._id)}
            className="size-5 cursor-pointer text-yellow-light_medium"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
            />
          </svg>

          <svg
            className="size-5 cursor-pointer text-red-500"
            onClick={() => remove([row._id], row.locationType)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </div>
      ),
      // sortable: true,
      // reorder: true,
    },
  ]

  const handleDateReset = () => {
    setStartDate("")
    setEndDate("")
    changeData()
    setIsFilterbyDate(false)
  }

  const formatDate = (date) => {
    const d = new Date(date)
    const year = d.getFullYear()
    const month = `0${d.getMonth() + 1}`.slice(-2) // Adding 1 because months are zero indexed
    const day = `0${d.getDate()}`.slice(-2)
    return `${year}-${month}-${day}`
  }

  useEffect(() => {
    if (!isForm) {
      setIsData({})
    }
  }, [isForm])

  const update = () => {
    dispatch(
      bookingAction(
        REDUX_STORE_DEFAULTS.UPDATE_BOOKING_DATA(),
        !isBookingUpdated
      )
    )
  }

  const refresh = () => {
    update()
    changeData(bookingDataType)
    dispatch(
      openToastify(
        "Data Updated Successfuly",
        REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
      )
    )
  }

  const handlePageChange = (page) => {
    console.log("Current Page:", page)
  }
  const handlePerRowsChange = (page) => {
    console.log("Current Page:", page)
  }

  const handleKeyDown = (e) => {
    // Prevent the default behavior for the up and down arrow keys
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault()
    }
  }
  return (
    <div className="m-2 md:m-10 mt-16 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex justify-between items-end">
        <Header title="Bookings" />
        {/* <button
          className="bg-yellow-light text-white h-8 px-3 font-medium rounded-lg hover:shadow-md"
          onClick={() => {
            setIsForm(true)
          }}
        >
          Create Booking
        </button> */}
      </div>

      {/* Filteration */}
      <div className="flex md:items-center text-xs space-x-2 justify-between md:flex-row flex-col">
        <div className="text-white space-x-2 flex items-center">
          <button
            className={` ${
              bookingDataType === "shop"
                ? "bg-yellow-light_medium"
                : "text-black border border-black"
            } h-8 px-5 rounded-lg `}
            onClick={() => {
              changeData("shop")
            }}
          >
            Shop
          </button>
          <button
            className={` ${
              bookingDataType === "mobile"
                ? "bg-yellow-light_medium"
                : "text-black border border-black"
            } h-8 px-5 rounded-lg `}
            onClick={() => {
              changeData("mobile")
            }}
          >
            Mobile
          </button>
          <button
            className={` ${
              bookingDataType === "all"
                ? "bg-yellow-light_medium"
                : "text-black border border-black"
            } h-8 px-5 rounded-lg `}
            onClick={() => {
              changeData("all")
            }}
          >
            All Bookings
          </button>
          <button
            title="Refresh Data"
            onClick={() => {
              refresh()
            }}
          >
            <svg
              className={` size-6 cursor-pointer text-green-600 ${
                isLoading ? "animate-spin" : ""
              } `}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </button>

          {selectedRows.length && bookingDataType !== "all" ? (
            <>
              <button
                title="Delete Bookings"
                onClick={() => remove(selectedRows, bookingDataType)}
              >
                <svg
                  className="size-6 cursor-pointer text-red-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="flex space-x-4 items-end text-xs md:flex-row flex- col mt-5 md:mt-0">
          <div className="flex space-x-4">
            <div className="flex flex-col space-y-1">
              <span>Start Date:</span>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="border p-1 rounded"
              />
            </div>
            <div className="flex flex-col space-y-1">
              <span>End Date:</span>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="border p-1 rounded"
              />
            </div>
          </div>
          <div className="flex mt-5 md:mt-0 space-x-4">
            {isFilterbyDate ? (
              <button
                onClick={handleDateReset}
                className="bg-slate-400 text-white h-8 px-5 text-base rounded-lg"
              >
                Clear
              </button>
            ) : (
              <button
                onClick={filterBookingDataByDate}
                className="bg-yellow-light_medium text-white h-8 px-5 text-base rounded-lg"
              >
                Filter
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-1 my-3 space-x-3">
        <input
          type="text"
          className="bg-slate-100 h-8 rounded-lg py-1 px-2 lg:w-[20%] w-full placeholder:text-sm border"
          placeholder="Seach by id, ph, email..."
          minLength={3}
          onKeyDown={handleKeyDown}
          value={isSearch}
          onChange={(e) => {
            setIsSearch(e.target.value)
          }}
        />
        <div className="w-20">
          {isSearch && (
            <button
              onClick={(e) => {
                setIsSearch("")
              }}
              className="bg-slate-400 text-white h-8 px-5 text-base rounded-lg"
            >
              Clear
            </button>
          )}
        </div>
        <button
          className="bg-yellow-light_medium text-white h-8 w-32 px-3 font-medium rounded-lg hover:shadow-md"
          onClick={() => {
            setIsForm(true)
          }}
        >
          Create
        </button>
      </div>

      <DataTable
        // title="Coupons List"
        columns={bookingGrid}
        data={bookingData ?? []}
        pagination
        fixedHeader
        selectableRows={bookingDataType !== "all"}
        fixedHeaderScrollHeight="480px"
        onChangePage={handlePageChange}
        // paginationTotalRows={100}
        onSelectedRowsChange={handleSelectedRowsChange}
      />
      {isLoading ? (
        <div className="flex justify-center w-full">
          <img src={Loader} alt="" className="w-12" />
        </div>
      ) : (
        <></>
      )}

      {/* booking form */}
      {isForm ? (
        <>
          <CreateBooking
            data={isData}
            onClick={closeEdit}
            isEdit={isEditData}
          />
        </>
      ) : (
        <></>
      )}

      {/* View form */}

      {isViewBooking ? (
        <>
          <ViewBooking data={isData} onClick={closeView} />
        </>
      ) : (
        <></>
      )}
    </div>
  )
}
export default Booking
